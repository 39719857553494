(() => {
  let images;
  const duration = 5000;

  function transition(index) {
    let next = index + 1;

    if (index === images.length - 1) {
      next = 0;
    }
    const nextAnimation = images[next].animate(
      {opacity: 1},
      {duration: 750, easing: 'ease-in-out', fill: 'forwards'}
      );

    const animation = images[index].animate(
      {opacity: 0},
      {duration: 1000, easing: 'ease-in-out', fill: 'forwards'}
    );

    animation.finished.then(() => {images[index].classList.remove('hero-stage__image--is-active')});
    nextAnimation.finished.then(() => {images[next].classList.add('hero-stage__image--is-active')});
    setTimeout(() => transition(next), duration);
  }

  function initHeroStage(module) {
    images = module.querySelectorAll('.js-image');
    setTimeout(() => transition(0), duration);

    module.classList.add('js-enhanced');
  }

  const modules = document.querySelectorAll('.hero-stage:not(.js-enhanced)');
  modules.forEach(initHeroStage);
})();
